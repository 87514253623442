// libraries
import React, { Fragment } from 'react';


export const shouldUpdateScroll = () => false;

export const wrapRootElement = ({ element }) => (
  <Fragment>
    <div id="root">{element}</div>
    <div id="portal"></div>
  </Fragment>
);
