// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-cloud-gaming-tsx": () => import("./../../../src/pages/cloud-gaming.tsx" /* webpackChunkName: "component---src-pages-cloud-gaming-tsx" */),
  "component---src-pages-confirmed-tsx": () => import("./../../../src/pages/confirmed.tsx" /* webpackChunkName: "component---src-pages-confirmed-tsx" */),
  "component---src-pages-discord-tsx": () => import("./../../../src/pages/discord.tsx" /* webpackChunkName: "component---src-pages-discord-tsx" */),
  "component---src-pages-downloads-tsx": () => import("./../../../src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-licensing-tsx": () => import("./../../../src/pages/licensing.tsx" /* webpackChunkName: "component---src-pages-licensing-tsx" */),
  "component---src-pages-local-co-op-online-tsx": () => import("./../../../src/pages/local-co-op-online.tsx" /* webpackChunkName: "component---src-pages-local-co-op-online-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-refund-tsx": () => import("./../../../src/pages/refund.tsx" /* webpackChunkName: "component---src-pages-refund-tsx" */),
  "component---src-pages-robot-tsx": () => import("./../../../src/pages/robot.tsx" /* webpackChunkName: "component---src-pages-robot-tsx" */),
  "component---src-pages-sdk-tsx": () => import("./../../../src/pages/sdk.tsx" /* webpackChunkName: "component---src-pages-sdk-tsx" */),
  "component---src-pages-teams-index-tsx": () => import("./../../../src/pages/teams/index.tsx" /* webpackChunkName: "component---src-pages-teams-index-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-warp-tsx": () => import("./../../../src/pages/warp.tsx" /* webpackChunkName: "component---src-pages-warp-tsx" */),
  "component---src-templates-docs-sdk-define-tsx": () => import("./../../../src/templates/docs/sdk/define.tsx" /* webpackChunkName: "component---src-templates-docs-sdk-define-tsx" */),
  "component---src-templates-docs-sdk-enum-tsx": () => import("./../../../src/templates/docs/sdk/enum.tsx" /* webpackChunkName: "component---src-templates-docs-sdk-enum-tsx" */),
  "component---src-templates-docs-sdk-func-tsx": () => import("./../../../src/templates/docs/sdk/func.tsx" /* webpackChunkName: "component---src-templates-docs-sdk-func-tsx" */),
  "component---src-templates-docs-sdk-index-tsx": () => import("./../../../src/templates/docs/sdk/index.tsx" /* webpackChunkName: "component---src-templates-docs-sdk-index-tsx" */),
  "component---src-templates-docs-sdk-struct-tsx": () => import("./../../../src/templates/docs/sdk/struct.tsx" /* webpackChunkName: "component---src-templates-docs-sdk-struct-tsx" */),
  "component---src-templates-docs-sdk-typedef-tsx": () => import("./../../../src/templates/docs/sdk/typedef.tsx" /* webpackChunkName: "component---src-templates-docs-sdk-typedef-tsx" */)
}

